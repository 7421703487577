// Create and setup your form here
 
<template>
  <div>
    <header class="vff-header">
      <div class="f-container">
        <img
        style="width: 100%"
        src="@/assets/logo.png"
        />
      </div>
    </header>

    <flow-form
      ref="flowform"
      v-on:complete="onComplete"
      v-on:submit="onQuizSubmit"
      v-bind:questions="questions"
      v-bind:language="language"
      v-bind:standalone="true"
      v-bind:timer="true"
    >
    <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
      <!-- We've overriden the default "complete" slot content -->
      <template v-slot:complete>
        <p>
          <span class="fh2" v-if="!submitted">最後一步！</span>
          <span class="fh2" v-if="submitted">結果出爐</span>
          <span v-if="!submitted" class="f-section-text">
            請確認您以上所填寫的個人資料正確後，點擊以下按鈕送出！
          </span>
        </p>
      </template>

      <!-- We've overriden the default "completeButton" slot content -->
      <template v-slot:completeButton>
        <div class="f-submit" v-if="!submitted">
          <button
            class="o-btn-action"
            ref="button"
            type="submit"
            href="#"
            v-on:click.prevent="onQuizSubmit()"
            aria-label="Press to submit">
            <span>送出資料</span>
          </button>
          <a 
            class="f-enter-desc"
            href="#"
            v-on:click.prevent="onQuizSubmit()"
            v-html="language.formatString(language.pressEnter)">
          </a>
        </div>
        <p class="text-success" v-if="submitted" >
          你的人格特質是：{{ final[0] }}<br>
          代表人物是：{{ final[1] }}<br>
          <img 
            style="margin-top: 10px"
            :src="final[3]"
          />
          <center>{{ final[2] }}</center><br>
          <span style="color: red">{{ final[4] }}</span>
        </p>
      </template>
    </flow-form>
  </div>
</template>

<script>
  import FlowForm from '../../src/components/FlowForm.vue'
  import QuestionModel, { QuestionType, ChoiceOption } from '../../src/models/QuestionModel'
  import LanguageModel from '../../src/models/LanguageModel'
  import axios from 'axios'
  import liff from '@line/liff'

  export default {
    name: 'example',
    components: {
      FlowForm
    },
    data() {
      return {
        submitted: false,
        completed: false,
        language: new LanguageModel({
          continue: '下一步',
          pressEnter: ''
        }),
        final: [],
        result: {
          personalityTraits: [
            "主動積極", 
            "欲擒故縱", 
            "天真瀾漫", 
            "被動等待"
          ],
          representative: [
            "安眠書店的Joe", 
            "單身即地獄的智雅",
            "當男人戀愛時的阿成",
            "一拳超人的琦玉"
          ],
          description: [
            "早安午安晚安，無時無刻都能和你「不小心」相遇。",
            "若即若離，一下進去一下出來，把每個人迷的團團轉。",
            "永遠都願意為你唱一首「愛情你比我想的閣較偉大」這樣的浪漫真的偉大。",
            "懶得動腦也懶得思考，只想被動等待沒有煩惱。"
          ],
          notice: [
            "＊建議當天多找找「當男人戀愛時的阿成」、與「單身即地獄的智雅」聊聊他們或許會與您特別來電呦。",
            "＊建議當天多找「安眠書店的Joe」與「一拳超人的琦玉」聊聊他們或許會與您特別來電呦。",
            "＊建議當天多找找「單身即地獄的智雅」、與「一拳超人的琦玉」聊聊，他們或許會與您特別來電呦。",
            "＊建議當天多找找「當男人戀愛時的阿成」、與「安眠書店的Joe」聊聊他們或許會與您特別來電呦。"
          ],
          avator: [
            require("./assets/result1.jpg"),
            require("./assets/result2.jpg"),
            require("./assets/result3.jpg"),
            require("./assets/result4.jpg"),
          ]
        },
        // Create question list with QuestionModel instances
        questions: [
          new QuestionModel({
            id: 'start',
            title: '心理測驗',
            content: '小建議：問卷回答內容將會在活動當天公佈您的特質，且會依此影響配對組別，請輕鬆並直覺的作答。',
            type: QuestionType.SectionBreak,
            required: true,
          }),
          new QuestionModel({
            id: 'psychological_1',
            title: '若你/妳使用交友軟體會往右滑 (Like!) 或是平常喜歡的類型？',
            type: QuestionType.MultipleChoice,
            required: true,
            multiple: false,
            helpTextShow: false,
            options: [
              new ChoiceOption({
                label: '運動陽光', 
                value: '1'
              }),
              new ChoiceOption({
                label: '文青氣息', 
                value: '2'
               }),
              new ChoiceOption({
                label: '雅痞街頭', 
                value: '3'
              }), 
              new ChoiceOption({
                label: '外表火辣', 
                value: '4'
              })
            ]
          }),
          new QuestionModel({
            id: 'psychological_2',
            title: '你/妳喜歡什麼樣的聊天氛圍？',
            helpTextShow: false,
            type: QuestionType.MultipleChoice,
            required: true,
            multiple: false,
             options: [
              new ChoiceOption({
                label: '秒讀秒回', 
                value: '1'
              }),
              new ChoiceOption({
                label: '有深度', 
                value: '2'
              }),
              new ChoiceOption({
                label: '幽默風趣', 
                value: '3'
              }),
              new ChoiceOption({
                label: '很會撩', 
                value: '4'
              })
            ]
          }),
          new QuestionModel({
            id: 'psychological_3',
            title: '如果要和對方約出去，第一次見面的情況下，你/妳會選擇以下哪個約會地點？',
            type: QuestionType.MultipleChoice,
            required: true,
            multiple: false, 
            helpTextShow: false,
            options: [
              new ChoiceOption({
                label: 'Netflix & Chill', 
                value: '1'
              }),
              new ChoiceOption({
                label: '餐廳', 
                value: '2'
              }),
              new ChoiceOption({
                label: '電影院', 
                value: '3'
              }), 
              new ChoiceOption({
                label: '你提 Idea 我隨意', 
                value: '4'
              })
            ] 
          }),
          new QuestionModel({
            id: 'psychological_4',
            title: '第一站結束後，你們選擇再去酒吧續攤，你/妳到酒吧後，會幫對方做以下哪件事情？',
            type: QuestionType.MultipleChoice,
            multiple: false,
            required: true,
            helpTextShow: false,
            options: [
              new ChoiceOption({
                label: '幫對方點加濃的酒',
                value: '1'
              }),
              new ChoiceOption({
                label: '故意和對方點一樣的酒然後說我也最愛喝這個',
                value: '2'
              }),
              new ChoiceOption({
                label: '與對方討論愛的真諦',
                value: '3' 
              }),
              new ChoiceOption({
                label: '等對方開話題', 
                value: '4'
              })
            ]
          }),
          new QuestionModel({
            id: 'psychological_5',
            title: '如果對方説下一個Round互相幫對方點酒，你/妳會幫對方選擇？',
            type: QuestionType.MultipleChoice,
            multiple: false,
            required: true,
            helpTextShow: false,
            options: [
              new ChoiceOption({
                label: '一排Shots直接尻', 
                value: '1'
              }),
              new ChoiceOption({
                label: '名稱最曖昧的酒款', 
                value: '2'
              }),
              new ChoiceOption({
                label: '心痛的感覺(水)', 
                value: '3'
              }),
              new ChoiceOption({
                label: '我不想選', 
                value: '4'
              })
            ]
          }),
          new QuestionModel({
            id: 'psychological_6',
            title: '對方若替你點了以下哪種基底，你/妳會覺得最不合意？',
            helpTextShow: false,
            type: QuestionType.MultipleChoice,
            multiple: false,
            required: true,
            options: [
              new ChoiceOption({
                label: '龍舌蘭 Tequila', 
                value: '1'
              }),
              new ChoiceOption({
                label: '伏特加 Vodka', 
                value: '2'
              }),
              new ChoiceOption({
                label: '威士忌 Whiskey', 
                value: '3'
              }),
              new ChoiceOption({
                label: '琴酒 Gin', 
                value: '4'
              })
            ]
          }),
          new QuestionModel({
            id: 'psychological_7',
            title: '酒吧結束後，你/妳會怎麼做？',
            type: QuestionType.MultipleChoice,
            multiple: false,
            required: true,
            helpTextShow: false,
            options: [
              new ChoiceOption({
                label: '去 Hotel', 
                value: '1'
              }),
              new ChoiceOption({
                label: '暗示對方要不要續攤', 
                value: '2'
              }),
              new ChoiceOption({
                label: '到公園散步', 
                value: '3'
              }),
              new ChoiceOption({
                label: '看對方帶我去哪都行', 
                value: '4'
              })
            ]
          }),
          new QuestionModel({
            id: 'psychological_8',
            title: '約會結束後，你/妳會做什麼？',
            helpTextShow: false,
            type: QuestionType.MultipleChoice,
            multiple: false,
            required: true,
            options: [
              new ChoiceOption({
                label: '馬上約下一次', 
                value: '1'
              }),
              new ChoiceOption({
                label: '只傳一個開心的貼圖給對方', 
                value: '2'
              }),
              new ChoiceOption({
                label: '清唱一首情歌傳給對方', 
                value: '3'
              }),
              new ChoiceOption({
                label: '什麼也不做', 
                value: '4'
              })
            ]
          }),
          new QuestionModel({
            title: '個人資料搜尋',
            content: '為了維護所有路跑活動參與者的安全，我們將為您投保團體活動保險，請確實填寫您的個人資料以便我們進行投保相關作業。',
            type: QuestionType.SectionBreak
          }),
          new QuestionModel({
            id: 'personal_name',
            title: '請輸入您的姓名',
            type: QuestionType.Text,
            required: true,
            placeholder: '例：王大明'
          }),

          new QuestionModel({
            id: 'personal_sex',
            title: '請選擇您的性別',
            helpTextShow: false,
            type: QuestionType.MultipleChoice,
            multiple: false,
            required: true,
            options: [
              new ChoiceOption({
                label: '男性', 
                value: '1'
              }),
              new ChoiceOption({
                label: '女性', 
                value: '2'
              })
            ]
          }),
          new QuestionModel({
            id: 'personal_id',
            title: '請輸入您的身份證字號',
            type: QuestionType.Text,
            required: true,
            placeholder: '例：A123456789'
          }),
          new QuestionModel({
            id: 'personal_birth',
            title: '請輸入您的出生年月日',
            type: QuestionType.Date,
            required: true,
            placeholder: ''
          }),
        ]
      }
    },
    methods: {
      /* eslint-disable-next-line no-unused-vars */
      onComplete(completed, questionList) {
        this.completed = completed
        console.log(questionList)
      },

      arrayEquals(a, b) {
        return Array.isArray(a) && Array.isArray(b) && a.length === b.length &&
          a.every((val, index) => val === b[index])
      },

      calculateScore() {
        let submitData = {
          "psychological": {},
          "personal": {}
        };
        let result = [0, 0, 0, 0];
        this.questions.forEach(question => {
          if (question.type !== QuestionType.SectionBreak) {
            let answer = question.answer
            console.log(`Question: `, question);
            console.log(`Answer: `, answer)
            let questionInResult = [3, 4, 5, 7, 8];
            // 心理測驗結果
            if(question.id.includes("psychological")){
              let q = parseInt(question.id.replace("psychological_", ""))
              if(questionInResult.indexOf(q) != -1){
                result[(answer-1)] += 1;
              }
              submitData["psychological"][question["title"]] = question["options"][parseInt(question["answer"]) - 1]["label"];
            }else{
              if(question.type == QuestionType.MultipleChoice){
                submitData["personal"][question["title"]] = question["options"][parseInt(question["answer"]) - 1]["label"];
              }else{
                submitData["personal"][question["title"]] = question["answer"];
              }
            }
          }
        })
        this.final = [
          this.result.personalityTraits[Math.max(...result)],
          this.result.representative[Math.max(...result)],
          this.result.description[Math.max(...result)],
          this.result.avator[Math.max(...result)],
          this.result.notice[Math.max(...result)]
        ];
        submitData["psychological"]["lineId"] = liff.getContext().userId;
        submitData["personal"]["lineId"] = liff.getContext().userId;
        submitData["psychological"]["人格特質"] = this.result.personalityTraits[Math.max(...result)];
        axios
          .post(
            `https://api.hwstud.io/v2/submit-submission.php?lineId=${liff.getContext().userId}`,
            submitData
          )
          .then(() => {
            console.log("Submmitted");
            alert("現在到 Line 中點選「顯示票券」就可以領票囉！")
          })
      },

      onQuizSubmit() {
        // Set `submitted` to true so the form knows not to allow back/forward
        // navigation anymore.
        this.$refs.flowform.submitted = true
        
        this.submitted = true
        this.calculateScore()
      }
    },
    created(){
      window.onload = function(){
        document.addEventListener('touchstart', function(e) {
          if(e.touches.length > 1){
            e.preventDefault();
          }
        })
        document.addEventListener('gesturestart', function(e) {
          e.preventDefault();
        })
      }
      liff.init({
        liffId: '1656804955-6Am9q31Y'
      })
      .then(() => {
        if(!liff.isLoggedIn()){
          liff.login()
        }else{
          axios
            .get(`https://api.hwstud.io/v2/verify-submission.php?lineId=${liff.getContext().userId}`)
            .then((result) => {
              let response = result.data;
              if(response.status == true){
                alert("您已經填寫過本測驗！")
                liff.closeWindow();
              }
            })
        }
      })
    }
  }
</script>

<style lang="css">
  @import '../../src/assets/css/themes/theme-purple.css';
  /* If using the npm package, use the following lines instead of the one above */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css'; */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css'; */
  div.f-answer span::before{
    content: '' !important;
  }
</style>